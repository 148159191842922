<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <logo />
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Reset Password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Your new password must be different from previously used passwords
          </b-card-text>

          <!-- form -->
          <validation-observer ref="resetPasswordForm">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                label="New Password"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="confirmPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                block
                type="submit"
                variant="primary"
              >
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'authentication-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BImg, BInputGroup, BInputGroupAppend, BLink, BRow } from 'bootstrap-vue';
import { computed, onMounted, ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import router, { useParams } from '@/router';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import useAuthentication from '@/modules/authentication/composables/useAuthentication';

import useAppConfig from '@core/app-config/useAppConfig';
import Logo from '@/layouts/components/Logo.vue';

export default {
  setup() {
    const toast = useToast();
    const { skin } = useAppConfig();
    const { resetPassword } = useAuthentication();

    const password1FieldType = ref('password');
    const password2FieldType = ref('password');
    const resetPasswordForm = ref();
    const password = ref('');
    const confirmPassword = ref('');

    let username = null;
    let token = null;

    onMounted(() => {
      const { query } = useParams().value;
      username = query.username.replaceAll(' ', '+');
      token = query.token.replaceAll(' ', '+'); // Format token string
    });

    return {
      //Reactive
      password1FieldType,
      password2FieldType,
      resetPasswordForm,
      password,
      confirmPassword,

      //Computed
      imgUrl: computed(() => (skin.value === 'dark'
        ? require('@/assets/images/pages/reset-password-v2-dark.svg')
        : require('@/assets/images/pages/reset-password-v2.svg'))),
      password1ToggleIcon: computed(() =>
        password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      ),
      password2ToggleIcon: computed(() =>
        password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      ),

      // Methods
      togglePassword1Visibility: () => {
        password1FieldType.value =
          password1FieldType.value === 'password' ? 'text' : 'password';
      },
      togglePassword2Visibility: () => {
        password2FieldType.value =
          password2FieldType.value === 'password' ? 'text' : 'password';
      },
      validationForm: () => {
        resetPasswordForm.value.validate().then(async (valid) => {
          if (valid) {
            const { success, message } = await resetPassword(
              username,
              token,
              password.value,
              confirmPassword.value
            );

            success
              ? toast.success({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                  },
                })
              : toast.error({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                  },
                });

            if (success) {
              router.push({ name: 'authentication-login' });
            }
          }
        });
      },

      // Validator
      required
    };
  },
  components: {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroupAppend,
    BInputGroup,
    BLink,
    BRow,
    ValidationObserver,
    ValidationProvider,
    Logo
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>